export const ApplicationRoutes = {
  account: {
    myProfile: '/my-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  admin: {
    users: '/admin/users'
  },
  crm: {
    companies: '/crm/companies'
  },
  inspectionAdmin: {
    view: '/inspection-admin/view',
    lockouts: '/inspection-admin/lockouts',
    selectList: '/inspection-admin/select-list',
    validation: '/inspection-admin/validation'
  }
};
