import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { NarrowLayout } from '../components/layout/narrowLayout';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes';

export const InspectionAdminRoutes: RouteItem = {
  text: 'Inspection Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.UserAdmin, AppRoles.Admin],
  layout: <NarrowLayout />,
  subItems: [
    {
      text: 'View Inspections',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.view,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../components/common/ComingSoon');
        return { Component: component.ComingSoon };
      }
    },

    {
      text: 'Lockouts',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.lockouts,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../components/common/ComingSoon');
        return { Component: component.ComingSoon };
      }
    },
    {
      text: 'Validation',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.validation,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../components/common/ComingSoon');
        return { Component: component.ComingSoon };
      }
    },
    {
      text: 'Select Lists',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.selectList,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/selectListAdmin/selectListAdmin');
        return { Component: component.SelectListAdmin };
      }
    }
  ]
};
